import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Offcanvas,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import EyeIcon from "../assets/svg/EyeIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/images/logo.png";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { isMobile } from "react-device-detect";
import { APP_CONST } from "../config/const";
import LoginModal from "../views/components/authModals/LoginModal";
import ModalWrapper from "./ModalWrapper";
import LanguageChange from "../views/components/LanguageChange";
import APK from "../assets/images/icons/Icon.png";
import { AiOutlineClose } from "react-icons/ai";
import CurrencyDropdown from "../views/components/CurrencyDropdown";
// import Currency from "../assets/images/currency-page/currency.svg";

const Header = () => {
  const [visible, setVisible] = useState(true);

  const isApk = localStorage.getItem("isApk") === "true";

  useEffect(() => {
    if (visible) {
      document.body.classList.add("download-apk");
    } else {
      document.body.classList.remove("download-apk");
    }
  }, [visible]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState(APP_CONST.AUTH_MODAL.LOGIN);
  const handleShow1 = () => setShow1(!show1);

  const handleModalShow = (modalName) => {
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };
  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = [
    "atlas-lobby",
    "betby",
    "exchange",
    "casino",
    "qtechbetgamestv",
    "promotions",
    "blog",
    "/",
    "qtech",
  ];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const formattedDate = currentDateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
  });

  return (
    <>
      {visible && !isApk ? (
        <div className="APKSection d-md-none d-flex">
          <div className="APKContent">
            <span onClick={() => setVisible(!visible)}>
              <AiOutlineClose />
            </span>
            <img src={APK} alt="star icon" />
            <div>
              <h4>Flamingo101</h4>
              <p>Add to Home screen</p>
            </div>
          </div>

          <div className="Installbtn">
            <a
              href="https://dl.dropbox.com/scl/fi/moxlyozn6hxe2lwuhq2p4/Flamingo101.apk?rlkey=19jemwgu7hkmbf77n2xcolede&st=8tr5etao&dl=0"
              download={
                "https://dl.dropbox.com/scl/fi/moxlyozn6hxe2lwuhq2p4/Flamingo101.apk?rlkey=19jemwgu7hkmbf77n2xcolede&st=8tr5etao&dl=0"
              }
              target="_blank"
              className="apkbtn"
            >
              Install
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
      <header className="header beforeheader">
        <div className="top_head">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo">
              <a href="/">
                <img src={appDetails?.LOGO_URL} alt="Logo" />
                {/* <img src={logo} alt="Logo" /> */}
              </a>
            </div>

            <div className="headerRight">
              <Button
                variant="primary"
                onClick={() => {
                  // !isMobile
                  // ?
                  handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  // : navigate("/sign-in");
                }}
                className="button-primary btn_signin"
              >
                <span className="d-none d-md-block">Log In</span>
                <span className="d-md-none">Sign In</span>
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  navigate("/mobile-number");
                }}
                className="button-primary btn_signup btn-secondary ms-3"
              >
                Sign Up
              </Button>
            </div>
          </div>
        </div>

        <div className="bottom_head">
          <div className="bottom-content">
            <ul className="d-none d-md-flex">
              <li>
                <a
                  href="/betby"
                  className={activePage == "betby" ? "active" : ""}
                >
                  <span>Sport</span>
                </a>
              </li>
              <li>
                <a
                  href="/exchange/Cricket"
                  className={activePage == "exchange" ? "active" : ""}
                >
                  <span>Live</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino"
                  className={activePage == "casino" ? "active" : ""}
                >
                  <span>Casino</span>
                </a>
              </li>
              <li>
                <a href="/" className={activePage == "/" ? "active" : ""}>
                  <span>Live Casino</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/qtechbetgamestv-bet-on-poker"
                  className={activePage == "qtechbetgamestv" ? "active" : ""}
                >
                  <span>Bet Games</span>
                </a>
              </li>
              <li>
                <a
                  href="/casino/qtech"
                  className={activePage == "qtech" ? "active" : ""}
                >
                  <span>Qtech</span>
                </a>
              </li>
              <li>
                <a
                  href="/promotions"
                  className={activePage == "promotions" ? "active" : ""}
                >
                  <span>Promotions</span>
                </a>
              </li>
              <li>
                <a
                  href="/blog"
                  className={activePage == "blog" ? "active" : ""}
                >
                  <span>Blog</span>
                </a>
              </li>
              <li onClick={() => handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)}>
                <a
                  href="#"
                  className={activePage == "atlas-lobby" ? "active" : ""}
                >
                  <span>Sportsbook</span>
                </a>
              </li>
            </ul>

            <div className="lang-time-currency">
              <CurrencyDropdown />
              <div className="search-games">
                <GamesSearchModal />
              </div>
              <div className="language ">
                <LanguageChange />
              </div>
              <div className="time">{formattedTime}</div>
            </div>
          </div>
        </div>

        {showAuthModals && (
          <ModalWrapper
            defaultShow={defaultModal}
            handleClose={setShowAuthModals}
          />
        )}
      </header>
    </>
  );
};

export default Header;
