import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../../assets/images/logo.png";
import BackArrow from "../../../../assets/images/icons/arrow-back.png";
import CurrencyBG from "../../../../assets/images/currency-page/currency-bg.jpg";
import USD from "../../../../assets/images/currency-page/USD.png";
import Dollar from "../../../../assets/images/currency-page/dollar.png";
import ArrowUSD from "../../../../assets/images/currency-page/arrow-usd.png";
import LPB from "../../../../assets/images/currency-page/LPB.png";
import Lebanese from "../../../../assets/images/currency-page/lebanese.png";
import ArrowLPB from "../../../../assets/images/currency-page/arrow-lpb.png";
import {
  FRONT_END_HOST,
  IS_PARENT_DOMAIN,
  ORG_CURRENCIES,
} from "../../../../config/domainConst";

const ChangeCurrency = ({ setIsCurrencySelected, cookies, setCookies }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (cookies?.currency) {
      // Currency already selected
      setIsCurrencySelected(true);
    }
  }, [cookies]);

  const handleCurrencyChange = (currency = ORG_CURRENCIES.USD) => {
    if (IS_PARENT_DOMAIN) {
      // Parent domain flamingo101.com
      if (currency === ORG_CURRENCIES.LPB) {
        window.location.href = `https://${FRONT_END_HOST.SUB_DOMAIN}?currency=${ORG_CURRENCIES.LPB}`;
      } else {
        setCookies("currency", ORG_CURRENCIES.USD);
      }
    } else {
      // Sub domain lbp.flamingo101.com
      if (currency === ORG_CURRENCIES.USD) {
        window.location.href = `https://${FRONT_END_HOST.PARENT_DOMAIN}?currency=${ORG_CURRENCIES.USD}`;
      } else {
        setCookies("currency", ORG_CURRENCIES.LPB);
      }
    }
    setIsCurrencySelected(true);
  };

  return (
    <>
      <div className="currency-page">
        <div className="inner-container">
          <div className="bg-img">
            <img src={CurrencyBG} alt="" />
          </div>
          <div className="back-btn" onClick={() => navigate(-1)}>
            <img src={BackArrow} alt="back" /> <span>Back</span>
          </div>

          <div className="content">
            <div className="logo">
              {/* <img src={appDetails?.LOGO_URL} alt="logo" /> */}
              <img src={Logo} alt="logo" />
            </div>

            {/* When user choose currency then add a class usd Or lpb immediatly and action should perform after 2 sec */}
            <div className="currency-options">
              <div
                className="currency usd"
                onClick={() => handleCurrencyChange(ORG_CURRENCIES.USD)}
              >
                <img src={USD} alt="USD" />
                <p>
                  <img src={Dollar} alt="dollar" /> United States Doller (USD)
                </p>
              </div>

              <div className="middle-sec">
                <img src={ArrowUSD} alt="arrow-usd" />
                <div className="text">Currency</div>
                <img src={ArrowLPB} alt="arrow-lpb" />
              </div>

              <div
                className="currency lpb"
                onClick={() => handleCurrencyChange(ORG_CURRENCIES.LPB)}
              >
                <img src={LPB} alt="LPB" />
                <p>
                  <img src={Lebanese} alt="lebanese" /> Lebanese Pound (LPB)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeCurrency;
