import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Whatsapp from "../../../../assets/images/icons/foot/whatsapp.png";
import Telegram from "../../../../assets/images/icons/foot/telegram.png";
import Instagram from "../../../../assets/images/icons/foot/instagram.png";
import ContactUsImg from "../../../../assets/images/contact1.png";

const BeforeContactUs = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <>
      <Header />

      <main className="main mt-5 footer-content">
        <div className="container">
          <h2 className="sectionTitle">Contact Us</h2>
          <div className="payment-info contact-pay-info">
            <div className="contact-img">
              <img src={ContactUsImg} />
            </div>
            <div class="social-icons contact-social">
              <ul>
                <li>
                  <a
                    className="panel"
                    href={
                      appDetails?.WHATS_APP_URL != ""
                        ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                        : "#"
                    }
                    target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                  >
                    {/* <span className="notification-count">2</span> */}
                    <img src={Whatsapp} alt="Whatsapp Icon" />
                    <p>Whats App</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"
                    }
                    target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Telegram} alt="Telegram Icon" />
                    <p> Telegram</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.INSTATGRAM != ""
                        ? appDetails?.INSTATGRAM
                        : "#"
                    }
                    target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                  >
                    <img src={Instagram} alt="Instagram Icon" />
                    <p>Instagram</p>
                  </a>
                </li>
                <li>
                  <a
                    href={
                      appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"
                    }
                    target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                  >
                    <img src="/images/facebook-512.svg" alt="Facebook Icon" />
                    <p>Facebook</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>

      <BeforeLoginFooter />
    </>
  );
};

export default BeforeContactUs;
