import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import Facebook from "../assets/images/icons/foot/facebook.png";
import Instagram from "../assets/images/icons/foot/instagram.png";
import Twitter from "../assets/images/icons/foot/twitter.png";
import Telegram from "../assets/images/icons/foot/telegram.png";
import Twitch from "../assets/images/icons/foot/twitch.png";
// import Whatsapp from "../assets/images/icons/foot/whatsapp.png";
import UpArrow from "../assets/images/icons/foot/up-arrow.svg";
import EgameLogo1 from "../assets/images/icons/foot/egame-logo1.png";
import EgameLogo2 from "../assets/images/icons/foot/egame-logo2.png";
import Plus18 from "../assets/images/icons/foot/plus18.png";
import Certified from "../assets/images/icons/foot/certified.jpeg";
import PmtMthdImg1 from "../assets/images/icons/foot/visa.png";
import PmtMthdImg2 from "../assets/images/icons/foot/interac.png";
import PmtMthdImg3 from "../assets/images/icons/foot/skrill.png";
import PmtMthdImg4 from "../assets/images/icons/foot/ecoPayz.png";
import PmtMthdImg5 from "../assets/images/icons/foot/ada.png";
import PmtMthdImg6 from "../assets/images/icons/foot/bch.png";
import PmtMthdImg7 from "../assets/images/icons/foot/btc.png";
import PmtMthdImg8 from "../assets/images/icons/foot/doge.png";
import PmtMthdImg9 from "../assets/images/icons/foot/ltc.png";
import PmtMthdImg10 from "../assets/images/icons/foot/pix.png";
import PmtMthdImg11 from "../assets/images/icons/foot/usdc.png";
import PlayStore from "../assets/images/icons/foot/play-store.png";
import SportsMenu from "../assets/images/icons/bot-menu/sports.svg";
import LiveMenu from "../assets/images/icons/bot-menu/live.svg";
import HomeMenu from "../assets/images/icons/bot-menu/home.png";
import CasinoMenu from "../assets/images/icons/bot-menu/casino.svg";
import LiveCasinoMenu from "../assets/images/icons/bot-menu/live-casino.svg";
import MenuMenu from "../assets/images/icons/bot-menu/menu.svg";
import ArrowRight from "../assets/images/icons/arrow-right.png";

const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");

  const footerPages = ["betby", "exchange", "casino", "menu", "/"];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="footer-menu-mobile d-none">
        <ul className="menu-list">
          <li className="title">INFORMATION</li>
          <li>
            <a href="/privacypolicy">
              <span>Privacy Policy </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
        </ul>
        <ul className="menu-list">
          <li className="title">ABOUT</li>
          <li>
            <a href="/affiliates">
              <span>Affiliates </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
          <li>
            <a href="/promotions">
              <span>Promotions </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
          <li>
            <a href="/contactus">
              <span>Contact Us </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
        </ul>
        <ul className="menu-list">
          <li className="title">Help</li>
          <li>
            <a href="/terms">
              <span>Terms and Conditions </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
          <li>
            <a href="/responsiblegame">
              <span>Responsible Gaming </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
        </ul>
        <ul className="menu-list">
          <li className="title">Services</li>
          <li>
            <a href="/home">
              <span>Casino </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
          <li>
            <a href="/betby">
              <span>Sports betting </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
          <li>
            <a href="/promotions">
              <span>Promotions </span>
              <img src={ArrowRight} alt="arrow right" />
            </a>
          </li>
        </ul>
      </div>

      <Container fluid>
        <div className="footer-top">
          <div className="footer-top-wrapper">
            <div className="social">
              <a
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Facebook} alt="facebook" />
              </a>
              <a
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
              {/* <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? appDetails?.WHATS_APP_URL
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a> */}
              <a href="https://twitter.com/intent/tweet">
                <img src={Twitter} alt="Telegram Icon" />
              </a>
              <a
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
              <a href="javascript:void(0)">
                <img src={Twitch} alt="Telegram Icon" />
              </a>
            </div>
            <div className="language"></div>
          </div>

          <button
            type="button"
            onClick={scrollToTop}
            className="up-button d-none d-md-flex"
          >
            <img src={UpArrow} alt="go to up" /> Up
          </button>
        </div>

        <div className="footer-grid">
          <div className="footer-info">
            <div className="footer-menu d-none d-md-block">
              <ul className="menu-list">
                <li className="title">INFORMATION</li>
                <li>
                  <a href="/privacypolicy">Privacy Policy</a>
                </li>
              </ul>
              <ul className="menu-list">
                <li className="title">ABOUT</li>
                <li>
                  <a href="/affiliates">Affiliates</a>
                </li>
                <li>
                  <a href="/promotions">Promotions</a>
                </li>
                <li>
                  <a href="/contactus">Contact Us</a>
                </li>
              </ul>
              <ul className="menu-list">
                <li className="title">Help</li>
                <li>
                  <a href="/terms">Terms and Conditions</a>
                </li>
                <li>
                  <a href="/responsiblegame">Responsible Gaming</a>
                </li>
              </ul>
              <ul className="menu-list">
                <li className="title">Services</li>
                <li>
                  <a href="/home">Casino</a>
                </li>
                <li>
                  <a href="/betby">Sports betting</a>
                </li>
                <li>
                  <a href="/promotions">Promotions</a>
                </li>
              </ul>
            </div>
            <div className="egaming-container">
              <div className="title">LICENSE</div>
              <div className="egaming-block">
                <div className="logo">
                  <a href="javascript:void(0)">
                    <img src={EgameLogo1} alt="egame logo" />
                  </a>
                </div>
                <div className="logo">
                  <a href="javascript:void(0)">
                    <img src={EgameLogo2} alt="egame logo" />
                  </a>
                </div>
              </div>
              <div className="egaming-text">
                <p>
                  Copyright © 2024 flamingo101.com is owned and operated by
                  Black Ocean B.V., a company registered and established under
                  the laws of Curaçao, with the registered address of
                  Franschebloemweg 4, Willemstad, Curaçao, and its wholly-owned
                  subsidiary, Ocean Payella Processing Limited, registered in
                  Cyprus with registration number HE 413534 and registered
                  address Voukourestiou, 25 NEPTUNE HOUSE, 1st floor,
                  Flat/Office 11 Zakaki, 3045, Limassol, Cyprus. Black Ocean
                  B.V. operates under License No. 8048/JAZ issued to Curaçao
                  eGaming, authorized and regulated by the Government of
                  Curaçao.
                </p>
                <div className="certified">
                  <a href="javascript:void(0)">
                    <img src={Plus18} alt="18+" />
                  </a>
                  <a href="javascript:void(0)">
                    <img
                      src={Certified}
                      alt="gaming certified"
                      className="cert"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-accept">
            <div className="payment-methods">
              <div className="title">PAYMENT METHODS</div>
              <ul className="methods-list">
                <li>
                  <img src={PmtMthdImg1} alt="visa" />
                </li>
                <li>
                  <img src={PmtMthdImg2} alt="interac" />
                </li>
                <li>
                  <img src={PmtMthdImg3} alt="skrill" />
                </li>
                <li>
                  <img src={PmtMthdImg4} alt="eco payz" />
                </li>
                <li>
                  <img src={PmtMthdImg5} alt="ada" />
                </li>
                <li>
                  <img src={PmtMthdImg6} alt="bch" />
                </li>
                <li>
                  <img src={PmtMthdImg7} alt="btc" />
                </li>
                <li>
                  <img src={PmtMthdImg8} alt="doge" />
                </li>
                <li>
                  <img src={PmtMthdImg9} alt="ltc" />
                </li>
                <li>
                  <img src={PmtMthdImg10} alt="pix" />
                </li>
                <li>
                  <img src={PmtMthdImg11} alt="usdc" />
                </li>
              </ul>
            </div>
            <div className="apk-link">
              <a href="javascript:void(0)" download="" target="_blank">
                <img src={PlayStore} alt="play store" />
              </a>
            </div>
          </div>
        </div>

        <div className="bottom-menu d-md-none">
          <ul>
            <li>
              <a
                href="/betby"
                className={activePage == "betby" ? "active" : ""}
              >
                <img src={SportsMenu} />
                <span>Sport</span>
              </a>
            </li>
            <li>
              <a
                href="/exchange/Cricket"
                className={activePage == "exchange" ? "active" : ""}
              >
                <img src={LiveMenu} />
                <span>Live</span>
              </a>
            </li>
            <li>
              <a href="/" className={activePage == "/" ? "active" : ""}>
                <img src={LiveCasinoMenu} />
                <span>Live Casino</span>
              </a>
            </li>
            <li>
              <a
                href="/casino"
                className={activePage == "casino" ? "active" : ""}
              >
                <img src={CasinoMenu} />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a href="/menu" className={activePage == "menu" ? "active" : ""}>
                <img src={MenuMenu} />
                <span>Menu</span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
};

export default BeforeLoginFooter;
