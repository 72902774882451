import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Slider from "react-slick";

const MainSlider = ({ banners }) => {
  const settings = {
    dots: false,
    fade: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            return (
              <div className="position-relative" key={index}>
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        {(b?.ctabtns.length &&
                          b.ctabtns.map((c, i) => {
                            return (
                              <Button
                                variant="primary"
                                key={i}
                                className="button-primary d-none"
                                onClick={() => {
                                  window.location.href = c.actionLink;
                                }}
                              >
                                {c.label}
                              </Button>
                            );
                          })) ||
                          null}
                      </div>
                    </div>
                  </div>
                </div>
                <img src={b.link} alt="First Slide" />
              </div>
            );
          })}
      </Slider>
    </>
  );
};

export default MainSlider;
