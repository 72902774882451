export const FRONT_END_HOST = Object.freeze({
  PARENT_DOMAIN: "flamingo1001.com",
  SUB_DOMAIN: "lbp.flamingo1001.com",
});

export const ORG_CURRENCIES = Object.freeze({
  USD: "USD",
  LPB: "LPB",
});

export const ORG_CURRENCIES_SIGN = Object.freeze({
  USD: "$",
  LPB: "LPB",
});

export const ORG_CURRENCY =
  window.location.host === FRONT_END_HOST.PARENT_DOMAIN
    ? ORG_CURRENCIES.USD
    : ORG_CURRENCIES.LPB;

export const IS_PARENT_DOMAIN =
  window.location.host === FRONT_END_HOST.PARENT_DOMAIN;
