import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  FRONT_END_HOST,
  IS_PARENT_DOMAIN,
  ORG_CURRENCIES,
} from "../../config/domainConst";

const CurrencyDropdown = ({ textColor = "#000000" }) => {
  const handleCurrencyChange = (currency = ORG_CURRENCIES.USD) => {
    if (IS_PARENT_DOMAIN) {
      // Parent domain flamingo101.com
      if (currency === ORG_CURRENCIES.LPB) {
        window.location.href = `https://${FRONT_END_HOST.SUB_DOMAIN}?currency=${ORG_CURRENCIES.LPB}`;
      }
    } else {
      // Sub domain lbp.flamingo101.com
      if (currency === ORG_CURRENCIES.USD) {
        window.location.href = `https://${FRONT_END_HOST.PARENT_DOMAIN}?currency=${ORG_CURRENCIES.USD}`;
      }
    }
  };

  return (
    <Dropdown align="end" className="d-none d-md-block">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        Select Currency
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => handleCurrencyChange(ORG_CURRENCIES.USD)}
          style={{ color: textColor }}
        >
          {ORG_CURRENCIES.USD}
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => handleCurrencyChange(ORG_CURRENCIES.LPB)}
          style={{ color: textColor }}
        >
          {ORG_CURRENCIES.LPB}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default CurrencyDropdown;
