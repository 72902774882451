import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Col, Button, Form } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
import { Toaster } from "../../../utils/toaster";
import { parsePhoneNumber } from "libphonenumber-js";
// import LoginLeftPart from "../../../assets/images/modal.gif";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import MumbaiSports from "../../../assets/images/icons/mumbaisports.png";
import UserName from "../../../assets/images/icons/icon-user.png";
import PasswordIcon from "../../../assets/images/icons/icon-pass.png";
import PhoneInput from "react-phone-input-2";
import EgameLogo1 from "../../../assets/images/icons/foot/egame-logo1.png";
import EgameLogo2 from "../../../assets/images/icons/foot/egame-logo2.png";
import Plus18 from "../../../assets/images/icons/foot/plus18.png";
import Certified from "../../../assets/images/icons/foot/certified.jpeg";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);
  const [mobileNumber, setMobileNumber] = useState("");
  const [changeModalContent, setChangeModalContent] = useState(true);
  const [otp, setOtpValue] = useState("");
  const [password, setPassword] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      handleClose();
      dispatch(loginUser(loginData));
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const loginDemoUser = () => {
    const payload = {
      username: process.env.REACT_APP_DEMO_USER_ID,
      password: process.env.REACT_APP_DEMO_USER_PASSWORD,
    };
    dispatch(loginUser(payload));
  };
  const handleSubmitForgotPass = async (e) => {
    e.preventDefault();
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            setIsOtpSent(true);
            // navigate("/reset-password");
            // handleClose();
            // handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  const handleSubmitResetPass = () => {
    setIsVerifying(true);

    if (otp == "" || password == "") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "All fields are mandatory");
    } else {
      const payload = {
        otp,
        password,
      };

      resetPassword(payload)
        .then((res) => {
          Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Password reset success");
          // navigate("/sign-in");
          // handleClose();
          // handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
          setChangeModalContent((prev) => !prev);
        })
        .catch((err) => {
          Toaster(
            APP_CONST.TOAST_TYPE.ERROR,
            err.response.data.message || "Request failed"
          );
          setIsVerifying(false);
        });
    }
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal LoginModal LoginScroll"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
        closeVariant="white"
      >
        <Modal.Title>SIGN IN</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {changeModalContent ? (
          <div
            className={
              !changeModalContent ? `login-section` : `login-section active`
            }
          >
            <div className="Login-rightPart">
              <div className="form-steps">
                <Form>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicName"
                  >
                    <Form.Control
                      type="text"
                      // placeholder="Enter Username*"
                      name="username"
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <label>
                      Log in <span>*</span>
                    </label>

                    {/* <img src={UserName} alt="UserName" className="InputImg1" /> */}
                    <Form.Control.Feedback type="invalid">
                      {errors?.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      // placeholder="Password*"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    {/* <img
                      src={PasswordIcon}
                      alt="PasswordIcon"
                      className="InputImg"
                    /> */}
                    <label>
                      Password <span>*</span>
                    </label>

                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    SIGN IN
                  </Button>
                </Form>

                <div className="sign-up signupsection">
                  Don't have an account <a href="/mobile-number">Sign Up</a>
                </div>

                <div className="d-flex justify-content-end">
                  <a
                    className="form-link"
                    onClick={() => {
                      // handleClose();
                      // handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                      setChangeModalContent((prev) => !prev);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div className="ModalFooter">
                <div className="footmenu-heading">
                  <h5>License</h5>
                </div>
                <div className="egaming-block">
                  <div className="logo">
                    <a href="javascript:void(0)">
                      <img src={EgameLogo1} alt="egame logo" />
                    </a>
                  </div>
                  <div className="logo">
                    <a href="javascript:void(0)">
                      <img src={EgameLogo2} alt="egame logo" />
                    </a>
                  </div>
                </div>
                <div className="egaming-text">
                  <p>
                    Copyright © 2024 flamingo101.com is owned and operated by
                    Black Ocean B.V., a company registered and established under
                    the laws of Curaçao, with the registered address of
                    Franschebloemweg 4, Willemstad, Curaçao, and its
                    wholly-owned subsidiary, Ocean Payella Processing Limited,
                    registered in Cyprus with registration number HE 413534 and
                    registered address Voukourestiou, 25 NEPTUNE HOUSE, 1st
                    floor, Flat/Office 11 Zakaki, 3045, Limassol, Cyprus. Black
                    Ocean B.V. operates under License No. 8048/JAZ issued to
                    Curaçao eGaming, authorized and regulated by the Government
                    of Curaçao.
                  </p>
                  <div className="certified">
                    <a
                      href="javascript:void(0)"
                      style={{
                        width: "63px",
                        height: "63ox",
                      }}
                    >
                      <img src={Plus18} alt="18+" />
                    </a>
                    <a
                      href="javascript:void(0)"
                      style={{
                        width: "108px",
                        height: "62px",
                      }}
                    >
                      <img
                        src={Certified}
                        alt="gaming certified"
                        className="cert"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={
              changeModalContent
                ? `login-section`
                : `login-section ForgotPasswordSection`
            }
          >
            <div className="Login-rightPart">
              <div className="form-steps mb-0">
                <Form onSubmit={handleSubmitForgotPass}>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <label>Phone Num</label>
                    <PhoneInput
                      country={"in"}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      onlyCountries={["in"]}
                      inputStyle={{
                        width: "100%",
                        lineHeight: 1.5,
                        padding: "0.7rem 0.75rem",
                        borderColor: "#26324B",
                        fontSize: "0.86rem",
                        minHeight: "41px",
                        paddingLeft: "50px",
                      }}
                      onChange={(phone) => {
                        // alert("phone", phone);
                        // alert(phone);
                        setMobileNumber(phone);
                      }}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmitForgotPass}
                  >
                    Get OTP
                  </Button>
                </Form>
                <Form className="mt-4">
                  <Form.Group className="mb-3 position-relative" controlId="">
                    <Form.Control
                      type="text"
                      // placeholder="Enter OTP*"
                      onChange={(e) => setOtpValue(e.target.value)}
                      autoComplete="off"
                    />
                    <label>Enter OTP</label>
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative" controlId="">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      // placeholder="Enter new password"
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                    />
                    <label>Enter new password</label>
                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </span>
                  </Form.Group>

                  <Button
                    variant="primary"
                    onClick={handleSubmitResetPass}
                    disabled={!isOtpSent}
                  >
                    {!isVerifying ? "SUBMIT" : "SUBMITTING"}
                  </Button>
                </Form>
                <a
                  className="mt-3 form-link"
                  href="javascript:void(0)"
                  onClick={() => setChangeModalContent((prev) => !prev)}
                >
                  Back To Login
                </a>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default LoginModal;
