import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import numberWithCommas from "../utils/numbersWithComma";
import { getUserBet } from "../redux/sports/actions";
import GamesSearchModal from "../views/components/GamesSearchModal";

import Profile from "../assets/images/icons/menu/profile.svg";
import Payout from "../assets/images/icons/menu/payout.svg";
import History from "../assets/images/icons/menu/bets.svg";
import Referral from "../assets/images/icons/menu/referral.svg";
import Bonus from "../assets/images/icons/menu/bonus.svg";
import Statement from "../assets/images/icons/menu/statement.svg";
import Logout from "../assets/images/icons/menu/logout.png";
import { ORG_CURRENCIES_SIGN, ORG_CURRENCY } from "../config/domainConst";
import CurrencyDropdown from "../views/components/CurrencyDropdown";
// import Currency from "../assets/images/currency-page/currency.svg";

const headerPages = [
  "atlas-lobby",
  "betby",
  "exchange",
  "qtechbetgamestv",
  "casino",
  "home",
  "promotions",
  "blog",
  "/",
  "/betby?bt-path=%2Flive",
  "qtech",
];

const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [showBalance, setShowBalanceLoading] = useState(false);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [showToggle, setShowToggle] = useState(true);
  const [showLightModeToggle, setShowLightModeToggle] = useState(false);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  useEffect(() => {
    if (showLightModeToggle) {
      document.body.classList.add("light-mode");
    } else {
      document.body.classList.remove("light-mode");
    }
  }, [showLightModeToggle]);

  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );

    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    setShowToggle(window.screen.width > 992);
    let balanceIntervalId = setInterval(() => {
      setShowBalanceLoading((p) => !p);
    }, 5000);

    return () => {
      clearInterval(balanceIntervalId);
      clearInterval(intervalId);
    };
  }, []);

  const formattedTime = currentDateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    // second: "numeric",
    hour12: true,
  });

  return (
    <header className="header aftrlgn">
      <div className="top_head">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="logo">
              <a href="/home">
                <img src={appDetails?.LOGO_URL} alt="Logo" />
                {/* <img src={logo} alt="Logo" /> */}
              </a>
            </div>
          </div>

          <div className="headerRight">
            {/* <div className="currency d-md-none">
              <a href="change-currency">
                <img src={Currency} alt="currency" />
              </a>
            </div> */}
            <CurrencyDropdown textColor={"#ffffff"} />
            <div className="search-games d-md-none">
              <GamesSearchModal />
            </div>

            {/* {renderBalance()} */}
            <div className="balance-info">
              <div className="balance">
                <div className="main-bal">
                  <span></span>{" "}
                  <small>{ORG_CURRENCIES_SIGN?.[ORG_CURRENCY]}</small>{" "}
                  <strong>{numberWithCommas(wallet?.cash || 0)}</strong>
                </div>
                <div className="bonus-bal d-none">
                  <span>Bonus</span>{" "}
                  <small>{ORG_CURRENCIES_SIGN?.[ORG_CURRENCY]}</small>{" "}
                  <span>{numberWithCommas(wallet?.coins)}</span>
                </div>
              </div>

              {/* <div className="deposit">
                <a href="/gatewaylist">Deposit</a>
              </div> */}
            </div>

            <Dropdown align="end" className="d-none d-md-block">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {user?.mstruserid}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/profile">
                  <img src={Profile} alt="profile" />
                  <span>Account Profile</span>
                </Dropdown.Item>
                {/* <Dropdown.Item href="/gatewaylist">
                  <img src={Deposit} alt="deposit" />
                  <span>Deposit</span>
                </Dropdown.Item> */}
                <Dropdown.Item href="/withdraw">
                  <img src={Payout} alt="Payout" style={{ padding: "0 2px" }} />
                  <span>Payout</span>
                </Dropdown.Item>
                <Dropdown.Item href="/mybets">
                  <img src={History} alt="bets" />
                  <span>History</span>
                </Dropdown.Item>
                <Dropdown.Item href="/referral">
                  <img
                    src={Referral}
                    alt="referral"
                    style={{ padding: "0 2px" }}
                  />
                  <span>Referral</span>
                </Dropdown.Item>
                <Dropdown.Item href="/bonus">
                  <img src={Bonus} alt="bonus" />
                  <span>Bonuses</span>
                </Dropdown.Item>
                <Dropdown.Item href="/cashier">
                  <img src={Statement} alt="statement" />
                  <span>Account Statement</span>
                </Dropdown.Item>
                <Dropdown.Item
                  href="/"
                  onClick={() => {
                    dispatch(logoutUser());
                  }}
                  className="logout"
                >
                  <img src={Logout} alt="logout" style={{ width: "18px" }} />
                  <span>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="bottom_head d-none d-md-block">
        <div className="bottom-content">
          <ul>
            <li>
              <a
                href="/betby"
                className={activePage == "betby" ? "active" : ""}
              >
                <span>Sport</span>
              </a>
            </li>
            <li>
              <a
                href="/betby?bt-path=%2Flive"
                className={activePage == "exchange" ? "active" : ""}
              >
                <span>Live</span>
              </a>
            </li>
            <li>
              <a
                href="/casino"
                className={activePage == "casino" ? "active" : ""}
              >
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a href="/home" className={activePage == "home" ? "active" : ""}>
                <span>Live Casino</span>
              </a>
            </li>
            <li>
              <a
                href="/casino/qtechbetgamestv-bet-on-poker"
                className={activePage == "qtechbetgamestv" ? "active" : ""}
              >
                <span>Bet Games</span>
              </a>
            </li>
            <li>
              <a
                href="/casino/qtech"
                className={activePage == "qtech" ? "active" : ""}
              >
                <span>Qtech</span>
              </a>
            </li>
            <li>
              <a
                href="/promotions"
                className={activePage == "promotions" ? "active" : ""}
              >
                <span>Promotions</span>
              </a>
            </li>
            <li>
              <a href="/blog" className={activePage == "blog" ? "active" : ""}>
                <span>Blog</span>
              </a>
            </li>
            <li>
              <a
                href="/atlas-lobby"
                className={activePage == "atlas-lobby" ? "active" : ""}
              >
                <span>Sportsbook</span>
              </a>
            </li>
          </ul>

          <div className="lang-time-currency">
            {/* <div className="currency">
              <a href="change-currency">
                <img src={Currency} alt="currency" />
              </a>
            </div> */}
            <div className="search-games d-none d-md-block">
              <GamesSearchModal />
            </div>
            <div className="time">{formattedTime}</div>
          </div>
        </div>
      </div>

      <Modal
        className="betslip_popup outer_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Exposure amount</th>
                    <th scope="col">Match name</th>
                    <th scope="col">Ref match ID</th>
                    <th scope="col">Total bets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup inner_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          <main className="main">
            <div className="container table-responsive">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Odds</th>
                    <th scope="col">Stack</th>
                    <th scope="col">Selection type</th>
                    <th scope="col">Status</th>
                    <th scope="col">Create date</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default HeaderAfterLogin;
