import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";
import Sports from "../../../../assets/images/icons/menu-page/sports.svg";
import Exchange from "../../../../assets/images/icons/menu-page/live.svg";
import Casino from "../../../../assets/images/icons/menu-page/casino.svg";
import LiveCasino from "../../../../assets/images/icons/menu-page/live-casino.svg";
import Betgames from "../../../../assets/images/icons/menu-page/betgames.svg";
import Promotions from "../../../../assets/images/icons/menu-page/promotions.svg";
import Blog from "../../../../assets/images/icons/menu-page/blog.svg";
import Supports from "../../../../assets/images/icons/menu-page/support.png";
import Qtech from "../../../../assets/images/icons/menu-page/qtech.png";

const Menu = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div className="my-menu">
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main HeaderMain">
        <div className="menu-page">
          <div className="menu-container">
            <div className="menu">
              <a href="/betby">
                <img src={Sports} alt="sports" />
                <span>Sports</span>
              </a>
              <a href="/exchange/Cricket">
                <img src={Exchange} alt="exchange" />
                <span>Live</span>
              </a>
              <a href="/">
                <img src={Casino} alt="casino" />
                <span>Live Casino</span>
              </a>
              <a href="/casino">
                <img src={LiveCasino} alt="live casino" />
                <span>Casino</span>
              </a>
              <a href="/casino/ezg-bet-on-teen-patti">
                <img src={Betgames} alt="betgames" />
                <span>Bet Games</span>
              </a>
              <a href="/casino/qtech">
                <img src={Qtech} alt="betgames" />
                <span>Qtech</span>
              </a>
              <a href="/promotions">
                <img src={Promotions} alt="promotions" />
                <span>Promotions</span>
              </a>
              <a href="/blog">
                <img src={Blog} alt="blog" />
                <span>Blog</span>
              </a>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Supports} alt="blog" />
                <span>Support 24 Hours</span>
              </a>
            </div>
          </div>
        </div>
      </main>

      {isAuth ? <Footer /> : <BeforeLoginFooter />}
    </div>
  );
};

export default Menu;
